import JSZip from "jszip";
import { saveAs } from "file-saver";
import { toPng } from "./html2picture";
import {IDEs, TEXT_CLASSNAMES, BACKGROUNDS} from "../data/constants";

export const exportLayouts = async (refs, product, channel) => {
  // const dropzones = document.querySelectorAll(".image--drop-zone");
  // if (dropzones.length > 0) [...dropzones].forEach(element => { element.classList.add("invisible") })

  const imgObjects = refs.map( async current => {

    const id = current.id;
    const html = current.children[0];


    const b64 = await toPng(html)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        img.crossOrigin = "anonymous";
        return img;
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });



    const w = b64.width;
    const h = b64.height;
    const ratio = window.devicePixelRatio;

    const actualW = w / ratio;
    const actualH = h / ratio;

    const image = document.createElement('canvas');
    image.width = actualW;
    image.height = actualH;
    const ctx = image.getContext('2d');
    ctx.drawImage(b64, 0,0, actualW, actualH);
    const url = image.toDataURL();

    return { url, w: actualW, h: actualH, id }
  })


  Promise.all(imgObjects)
    .then( array => {
      const zip = new JSZip();

      array.forEach((obj, i) => {
        // const name =  Object.keys(refs)[i];
        const name =  `${product}-${channel}-${obj.id}-${obj.w}x${obj.h}`;
        zip.file(
          `${name}.png`,
          obj.url.substring("data:image/png;base64,".length),
          {base64: true}
        );
      })

      return zip.generateAsync({type: "blob"});
    })
    .then(result => {
      const name = `${product}-${channel}`;
      saveAs(result, name);
    })
    .catch((e) => console.log('Error downloading images', e));

  // if (dropzones.length > 0) [...dropzones].forEach(element => { element.classList.remove("invisible") })
}

export const isEmptyObj = obj => {
  if (!obj) return;
  const isEmpty = Object.keys(obj).length === 0;
  return isEmpty;
}

export const changeFontSize = (elems, coef, styleEl) => {
  elems.forEach(div => {
    const currentSize = getComputedStyle(div).fontSize;
    const fz = `calc(${coef}*${currentSize})`;
    const classname = `font-size${(Math.random() * 1000000).toFixed()}`;
    const oldFontClasses = [...div.classList].filter(cl => (cl.includes("font-size")));
    if (oldFontClasses.length > 0) div.classList.remove(oldFontClasses);
    div.classList.add(classname);
    styleEl.sheet.insertRule(`.${classname} {font-size:${fz}!important}`);
  })
}

export const getImageOrientation = image =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img.width > img.height ? "horiz" : "vert");
    };

    img.onerror = reject;

    img.src = image.data_url;
  });


export const checkIfIDE = (product) => ( IDEs.some(ide => product === ide) );

export const findParentWithClass = (element, classNames) => {
  let currentElement = element;

  // Traverse up the DOM tree
  while (currentElement.parentElement) {
    currentElement = currentElement.parentElement;

    // Check if the current parent element has any of the class names from the array
    for (let className of classNames) {
      if (currentElement.classList.contains(className)) {
        return className;
      }
    }
  }

  // Return null if no parent with any of the specified class names is found
  return '';
}


export const checkImageLoaded = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function() {
      resolve(true);
    };

    img.onerror = function() {
      // console.log(url)
      resolve(false); // or you can use reject(new Error('Image failed to load')) if you prefer
    };

    img.src = url;
  });
};


export const getHashes = arraysOfArrays => {
    const result = {};

    arraysOfArrays.forEach((subArray, outerIndex) => {
      for (let innerIndex = 0; innerIndex < subArray.length; innerIndex++) {
        if (subArray[innerIndex].includes('#')) {
          result[outerIndex+1] = extractHash(subArray[innerIndex]);
          break; // Stop checking other elements in the same sub-array once we've found a '#'
        }
      }
    });
    return Object.keys(result).length ? result : {};
  }


  const extractHash = string => {
    const hashIndex = string.indexOf('#');

    if (hashIndex === -1) {
      return '';
    }

    const dotIndex = string.indexOf('.', hashIndex);

    if (dotIndex === -1) {
      return string.substring(hashIndex + 1);
    }

    return string.substring(hashIndex + 1, dotIndex);
  }


  export const getBgUrl = (channel, type) => {
    if (type) {
      console.log(channel, type)
      return `${BACKGROUNDS}/${channel}/${type}`;
    } else {
      console.log(channel, type)
      return `${BACKGROUNDS}/${channel}`;
    }
  }

export const arrayToObject = arr => {
  const result = {};
  arr.forEach((subArray, index) => {
    // Use (index + 1).toString() to get the key as a string number starting from "1"
    const key = (index + 1).toString();

    // Assign the subArray to the object under the generated key
    result[key] = subArray;
  })
  return result;
}


export const findBgUrl = (arr, index, substring) => {
  console.log('searching bg', arr, index, substring)
  return arr[index-1].find(url => ( url.includes(substring)));
}