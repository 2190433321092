import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { Select } from '@rescui/select';
import Button from '@rescui/button';
import { Toggle } from '@rescui/toggle';
import {CHANNELS, PRODUCTS} from '../../data/products.js';
import { useNavigate } from "react-router-dom";
import {arrayToObject, containsHash, getHashes} from "../../libs/tools";
import {useEdits} from '../../contexts/edits.js';
import {useSetting} from '../../contexts/setting.js';
import {useImages} from "../../contexts/images";
import BgSelector from "../BgSelector";
import LayoutSelector from "../LayoutSelector";
import IconSelector from "../IconSelector";
import FontSelector from "../FontSelector";
import TemplateSelector from "../TemplateSelector";
import {API} from "../../api";
import {DEFAULT_BACKGROUND_DATA} from "../../data/backgrounds";

const Toolbar = (props ) => {

  const { handleExport, handleSaveScene } = props;
  const navigate = useNavigate();
  const edits = useEdits();
  const { editBgHashes, bgHashes, editBgData, rerenderBg, editTransform, editAllBgUrls } = useImages();
  const { product, editBgOption, editProduct, channel, editChannel, type, editType, layoutOption, bgOption, hash, isDropzoneVisible, makeDropzoneVisible, layoutAmount } = useSetting();

  const [isFontToggle, setIsFontToggle] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [bgAmount, setBgAmount] = useState(9);
  const [iconAmount, setIconAmount] = useState(6);
  const [ copyButtonText, setCopyButtonText ] = useState("Copy link");



  const saveSceneTrigger = () => {
    handleSaveScene();
    setCopyButtonText("Copied!")
    setTimeout(() => {
      setCopyButtonText("Copy link");
    }, 3000)
  }

  const updateChannelList = () => {
    const list = CHANNELS.map(ch => ({ label: ch.label, value: ch.value}) );
    setChannelList(list);
  }
  const updateProductList = (ch = channel, reset = false) => {
    const channelObject = CHANNELS.find(obj => obj.value === ch.value);
    const requiredProducts = channelObject.products;
    const prodList = PRODUCTS.filter(p => requiredProducts.some(required => required === p.value));
    setProductList(prodList);
    if (reset) editProduct(prodList[0]);
  }

  const updateTypeList = (prod = product, reset = false) => {
    const channelObject = CHANNELS.find(ch => ch.value === channel.value);
    if (channelObject.types) {
      // console.log(prod.value)
      const filtered = channelObject.types.filter(typeObj => (
        typeObj.products.includes(prod.value)
      ))
      const pureTypes = filtered.map(t => ({ label: t.label, value: t.value }));
      setTypeList(pureTypes);
      if (reset) editType(pureTypes[0]);
    } else {
      setTypeList([]);
    }
  }


  const onChangeChannel = value => {
    editChannel(value);
    edits.reset();
    editBgOption("1");
    updateProductList(value, true);
    // updateTypeList(value, true)
    console.log('redirect to ',value.value )
    navigate(`/${value.value}`)
  }

  const onChangeProduct = value => {
    editProduct(value);
    console.log('product change', value);
    updateTypeList(value);
    edits.editProductNames({main: value.label})
    const nextUrl = `${channel.value}/${value.value}`;
    console.log('redirect to ', nextUrl)
    navigate(nextUrl);
  }

  const onChangeType = value => {
    editType(value);
    editBgOption("1");
  }

  useEffect(() => {
    updateTypeList();
  }, [channel, product]);

  useEffect(() => {
    updateChannelList();
    updateProductList();
    updateTypeList();
  }, []);

  const getBgTransforms = async bgs => {
    const hashes = getHashes(bgs);
    editBgHashes(hashes);

    if (Object.keys(hashes).length) {
      // Create an array of promises
      const promises = Object.keys(hashes).map((key) => {
        if (hashes.hasOwnProperty(key)) {
          const h = hashes[key];
          // Return promise
          return API.getTransforms(h).then((result) => ({ key, result }));
        }
        return null;
      });

      // Waiting for all promises to fulfill
      const results = await Promise.all(promises);

      // Final object
      const output = results.reduce((acc, { key, result }) => {
        acc[key] = result;
        return acc;
      }, {});

      if (!hash) {
        const {x, y, scale} = output[bgOption] || DEFAULT_BACKGROUND_DATA;
        editTransform(x, y, scale);
        rerenderBg();
      }

      return output;
    } else {
      return {};
    }
  };


  useEffect(() => {
    let isCancelled = false;  // Flag to track unresolved requests

    const getBgs = async () => {
      try {
        const bgs = await API.getBackgrounds(channel.value, type.value);

        if (!isCancelled) {
          const transforms = await getBgTransforms(bgs);
          editAllBgUrls(bgs);
          editBgData(transforms);
          setBgAmount(bgs.length);

          if (bgs.length < 1) {
            console.log('no backgrounds found');
          }
        }
      } catch (error) {
        if (!isCancelled) {
          console.error(error);
        }
      }
    };

    getBgs();

    // Cancel the request when unmounting or dependencies update
    return () => {
      isCancelled = true;
    };
  }, [channel, type]);


  return (
    <aside>
      <div className="toolbar-inner">
        <span className="label">Channel</span>
        <Select
          className="select"
          options={channelList}
          value={channel}
          onChange={value => onChangeChannel(value)}
          size="m"
          placeholder="Select template"
        />

        <span className="label">Product</span>
        <Select
          className="select"
          options={productList}
          value={product}
          onChange={value => onChangeProduct(value)}
          size="m"
          placeholder="Select product"
        />

        {typeList?.length > 0 && (
          <>
            <span className="label">Content type</span>
            <Select
              className="select"
              options={typeList}
              value={type}
              onChange={value => onChangeType(value)}
              size="m"
              placeholder="Select type"
            />
          </>
        )}
        <div className="localization-wrap">
          <div className="localization">
            <span className="label">Localization</span>
            <Toggle checked={isFontToggle} className="font-toggle" onChange={e => {
              setIsFontToggle(e.target.checked);
            }}>
            </Toggle>
          </div>

          {isFontToggle && <FontSelector/>}

        </div>

        {channel.value !== "livestream" && (
          <>
            <span className="label">Layout</span>
            <LayoutSelector amount={layoutAmount}/>
          </>
        )}


        {+layoutOption === 7 && (
          <>
            <span className="label">Icon</span>
            <IconSelector amount={iconAmount}/>
          </>
        )}



        <span className="label">Background</span>
        <BgSelector amount={bgAmount}/>


        <div className="localization-wrap">
          <div className="localization">
            <span className="label">{`Dropzone ${isDropzoneVisible ? "visible" : "hidden"}`}</span>
            <Toggle checked={isDropzoneVisible} className="font-toggle" onChange={e => {
              makeDropzoneVisible(e.target.checked);
            }}>
            </Toggle>
          </div>
        </div>



        <span className="label">Templates</span>
        <TemplateSelector />


        <div className="toolbar-btns">
          <Button
            className="btn"
            onClick={saveSceneTrigger}
            size="m"
            mode="outline"
            id={"save_button"}
          >
            <span>{copyButtonText}</span>
          </Button>

          <Button
            className="btn"
            onClick={handleExport}
            size="m"
            id={"export_button"}
            mode="outline"
          >
            <span>Save images</span>
          </Button>
        </div>
      </div>
    </aside>
  );
}

export default Toolbar;