import React, { useState } from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { logoNorm, logoIDE, UI} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const YouTubeFirstSlideLivestream = ({ id  }) => {
  const { product, layoutOption, iconOption } = useSetting();
  const { bgUrl } = useImages();
  const [isInline, setIsInline] = useState(true);
  const { titles, names, badges, positions, } = useEdits();
  const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;
  const title = titles[id] || titles.main;
  const name = names[id] || names.main;
  const badge = badges[id] || badges.main;
  const position = positions[id] || positions.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`
  const templateClass = cn("template", `layout-${layoutOption}`);
  const logoClass = cn("logo", {"ide": checkIfIDE(product.value)});

  return (
    <>

      <div id={id} className={templateClass}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <div className="badge">
              <Textarea id={id} text={badge} inline={isInline}/>
            </div>

            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background"/>
              </div>
              {product.value === "JB" ? "" : (
                <div className="product-name">
                  {product.label}
                </div>
              )}
            </header>

            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title}/>
              </h1>


            </div>

            <div>
            <h2 className="name">
              <Textarea id={id} text={name}/>
            </h2>

            <h2 className="position">
              <Textarea id={id} text={position}/>
            </h2>
            </div>

          </div>

          <div className="upload-wrap">
            <ImageUpload id={id}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default YouTubeFirstSlideLivestream;