import React, {useState} from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { logoNorm, logoIDE, UI} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const Email02 = ({ id }) => {
    const {product, layoutOption, iconOption} = useSetting();
    const {titles, subtitles, badges, } = useEdits();
    const [isInline, setIsInline] = useState(true);

    const {bgUrl} = useImages();
    const title = titles[id] || titles.main;
    const badge = badges[id] || badges.main;
    const templateClass = cn("template", `layout-${layoutOption}`);

  return (
    <>

      <div id={id} className={templateClass}>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content" >
            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title}/>
              </h1>
              {+layoutOption === 3 && (
                  <div className="badge">
                      <Textarea id={id} text={badge} inline={isInline}/>
                  </div>
              )}
            </div>
          </div>
      </div>
    </>
  );
}

export default Email02;