export const DEFAULT_BACKGROUND_DATA = {
  x: {
    Twitter: -100,
    Facebook: -100,
    InstagramStories: -288,
    InstagramPost: -100,
    LinkedIn: -100,
    YouTubeCommunity: 0,
    BlogFeatured: -100,
    BlogSocialShare: -100,
    YouTubeThumbnailLivestream: -200,
  },
  y: {
    Twitter: -1625,
    Facebook: -1666,
    InstagramStories: -1680,
    InstagramPost: -1625,
    LinkedIn: -1625,
    YouTubeCommunity: 0,
    BlogFeatured: -1625,
    BlogSocialShare: -1625,
    YouTubeThumbnailLivestream: -1625,

  },
  scale: {
    Twitter: 1,
    Facebook: 1,
    InstagramStories: 1.65,
    InstagramPost: 1,
    LinkedIn: 1,
    YouTubeCommunity: 1,
    BlogFeatured: 1,
    BlogSocialShare: 1,
    YouTubeThumbnailLivestream: 1,
  }
};

