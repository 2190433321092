import React, {useState} from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import {logoNorm, logoIDE, UI} from "../../../data/constants";
import {checkIfIDE} from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const Email01 = ({id}) => {
    const {product, layoutOption, iconOption} = useSetting();
    const {titles, subtitles, badges} = useEdits();
    const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;
    const badge = badges[id] || badges.main;
    const [isInline, setIsInline] = useState(true);

    const {bgUrl, bgUrl2x} = useImages();
    const iconUrl = `${UI}/Icons/${iconOption}.svg`
    const title = titles[id] || titles.main;
    const subtitle = subtitles[id] || subtitles.main;
    const templateClass = cn("template", `layout-${layoutOption}`);
    const logoClass = cn("logo", {"ide": checkIfIDE(product.value)});

    return (
        <>
            <div id={id} className={templateClass}>
                <BgTransformer src={bgUrl} id={id}/>

                <div className="content">
                    <div className="title-wrap">
                        {+layoutOption === 1 && (
                            <h1 className="title">
                                <Textarea id={id} text={title}/>
                            </h1>
                        )}
                        {+layoutOption === 2 && (
                            <div className="title">
                                {product.value === "JB" ? "" : (
                                    <div className="product-name">
                                        {product.label}
                                    </div>
                                )}
                                <div className="badge">
                                    <Textarea id={id} text={"202X.X EAP"} inline={isInline}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Email01;