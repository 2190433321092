import React from "react";
import "./index.css";
import Textarea from "../../../layout/Textarea/Textarea";
import {logoNorm, logoIDE, UI} from "../../../data/constants";
import {checkIfIDE} from "../../../libs/tools";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import cn from "classnames";
import Icon from "../../../layout/Icon";
import ImageUpload from "../../../layout/ImageUpload";
import {useImages} from "../../../contexts/images";

const InstagramStoriesLivestream = ({ id }) => {
  const { product, layoutOption, iconOption } = useSetting();
  const { titles, badges, dates } = useEdits();
  const { bgUrl } = useImages();
  const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;

  const title = titles[id] || titles.main;
  const badge = badges[id] || badges.main;
  const date = dates[id] || dates.main;
  const templateClass = cn("template", `layout-${layoutOption}`);
  const logoClass = cn("logo", {"ide": checkIfIDE(product.value)});

  return (
    <>
      <div id={id} className={templateClass}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <div className="title-wrap">
              <header className="template-header">
                <div className={logoClass}>
                  <img src={logoURL} alt="background"/>
                </div>
                {product.value === "JB" ? "" : (
                  <div className="product-name">
                    {product.label}
                  </div>
                )}
              </header>

              <div className="badge">
                <Textarea id={id} text={badge} inline={true}/>
              </div>

              <h1 className="title">
                <Textarea id={id} text={title}/>
              </h1>

            </div>
            <div className="date">
              <Textarea id={id} text={date}/>
            </div>
            <div className="upload-wrap">
              <ImageUpload id={id}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstagramStoriesLivestream;