import React, { useContext, useState, createContext, useCallback } from "react";

import {TAGS} from "../data/constants";
import {CHANNELS} from "../data/products";
import {PRODUCTS} from "../data/products";

export const Setting = createContext(
  {
    setting: {},
    editSetting: () => {},
  });




export const SettingContextProvider = ({ children }) => {

  const [ channel, setChannel ] = useState({label: CHANNELS[0].label, value: CHANNELS[0].value});
  const [ product, setProduct ] = useState({label: PRODUCTS[0].label, value: PRODUCTS[0].value});
  const [ type, setType ] = useState({label: "General", value: "general"});
  const [ bgOption, setBgOption ] = useState("1");
  const [ layoutOption, setLayoutOption ] = useState("1");
  const [ iconOption, setIconOption ] = useState("key");
  const [ fontOption, setFontOption ] = useState("en");
  const [ palettes, setPalettes ] = useState({});
  const [ fontVariation, setFontVariations ] = useState({});
  const [ tag, setTag ] = useState(TAGS[2]);
  const [ locked, setLocked ] = useState([]);
  const [ visible, setVisible ] = useState([]);
  const [ hash, setHash ] = useState("");
  const [ isDropzoneVisible, setDropzoneVisible ] = useState(true);
  const [ layoutAmount, setLayoutAmount ] = useState(7);


  // TODO remove this crutch
  const makeDropzoneVisible = value => {
    setDropzoneVisible((current) => {
      const wrappers = document.querySelectorAll(".upload-wrap");
      [...wrappers].forEach(el => {
        console.log(value);
        if (value !== true) {
          el.classList.add("collapsed")
        } else {
          if (el.classList.contains('collapsed')) {
            el.classList.remove("collapsed");
          }
        }
      })
      return !current
    });
  };

  const addVisible = value => {
    setVisible((curList) => {
      const nextList = [...curList];
      nextList.push(value);
      return nextList;
    });
  };
  const removeVisible = value => {
    setVisible((curList) => {
      return [...curList].filter(item => item !== value);
    });
  };

  const editVisible = (template, value) => {
    if (value === true) {
      addVisible(template);
    } else {
      removeVisible(template)
    }
  }

  const addLocked = value => {
    setLocked((curList) => {
      const nextList = [...curList];
      nextList.push(value);
      return nextList;
    });
  };
  const removeLocked = value => {
    setLocked((curList) => {
      return [...curList].filter(item => item !== value);
    });
  };

  const editLocked = (template, value) => {
    if (value === true) {
      addLocked(template);
    } else {
      removeLocked(template)
    }
  }

  const loadLocked = array => {
    setLocked(array);
  }

  const editLayoutAmount = value => {
    setLayoutAmount(value);
  }

  const loadVisible = array => {
    setVisible(array);
  }

  const editFontOption = value => {
    setFontOption(value);
  };
  const editBgOption = value => {
    setBgOption(value);
  };

  const editHash = value => {
    setHash(value);
  }

  const editTag = value => {
    setTag(value);
  }
  const editIconOption = value => {
    setIconOption(value);
  };

  const editLayoutOption = value => {
    setLayoutOption(value);
  };
  const editType = value => {
    setType(value);
  };
  const editChannel = value => {
    setChannel(value);
  };

  const editProduct = value => {
    setProduct(value);
  }

  const editPalettes = value => {
    setPalettes(value);
  };

  const editFontVariations = value => {
    setFontVariations(value);
  }

  return (
    <Setting.Provider value={{
      channel,
      product,
      type,
      palettes,
      fontVariation,
      bgOption,
      layoutOption,
      iconOption,
      tag,
      fontOption,
      visible,
      locked,
      hash,
      isDropzoneVisible,
      editLayoutAmount,
      makeDropzoneVisible,
      addVisible,
      removeVisible,
      addLocked,
      removeLocked,
      editFontOption,
      editChannel,
      editProduct,
      editType,
      editPalettes,
      editFontVariations,
      editBgOption,
      editLayoutOption,
      editIconOption,
      editTag,
      editLocked,
      loadLocked,
      loadVisible,
      editVisible,
      editHash,
      layoutAmount,
    }}>
      { children }
    </Setting.Provider>
  );
}



export function useSetting() {
  const context = useContext(Setting);
  if (context === undefined) {
    throw new Error("Setting.Context must be used within the Images.Provider");
  }
  return context;
}