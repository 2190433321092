import React, { useState } from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { logoNorm, logoIDE, UI} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const EmailBannerLivestream = ({ id  }) => {
  const { product, layoutOption, iconOption } = useSetting();
  const { bgUrl } = useImages();
  const [isInline, setIsInline] = useState(true);
  const { titles, names } = useEdits();
  const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;
  const title = titles[id] || titles.main;
  const name = names[id] || names.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`
  const templateClass = cn("template", `layout-${layoutOption}`);
  const logoClass = cn("logo", {"ide": checkIfIDE(product.value)});

  return (
    <>

      <div id={id} className={templateClass}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">

            <h1 className="title">
              <Textarea id={id} text={title}/>
            </h1>
            <h2 className="name">
              <Textarea id={id} text={name}/>
            </h2>

          </div>

        </div>
      </div>
    </>
  );
}

export default EmailBannerLivestream;