import React, { useContext, useState, createContext } from "react";


export const Edits = createContext(
  {
    names: {},
    titles: {},
    subtitles: {},
    badges: {},
    positions: {},
    dates: {},
    fontVariation: {},
    productNames: {},
    editProductNames: {},
    images: {},
    text1: {},
    text2: {},
    bgTransform: {},
    editNames: () => {},
    editTitles: () => {},
    editDates: () => {},
    editPositions: () => {},
    editSubtitles: () => {},
    editBadges: () => {},
    reset: () => {},
    editFontVariation: () => {},
    editImages: () => {},
    editText1: () => {},
    editText2: () => {},
    editBgTransform: () => {},
  });




export const EditsContextProvider = ({ children }) => {

  const [ names, setNames ] = useState({});
  const [ titles, setTitles ] = useState({});
  const [ subtitles, setSubtitles ] = useState({});
  const [ positions, setPositions ] = useState({});
  const [ dates, setDates ] = useState({});
  const [ badges, setBadges ] = useState({});
  const [ productNames, setProductNames ] = useState({});
  const [ fontVariation, setFontVariation ] = useState(1);
  const [ images, setImages ] = useState({});
  const [ text1, setText1 ] = useState({});
  const [ text2, setText2 ] = useState({});


  const reset = () => {
    setNames({});
    setTitles({});
    setDates({});
    setPositions({});
    setProductNames({});
    setFontVariation(1);
    setImages({});
    setText1({});
    setText2({});
    setSubtitles({});
  }



  const editSubtitles = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setSubtitles((curTitles) => {
      const nextTitles = {...curTitles};
      if (!nextTitles[currentId]) { nextTitles[currentId] = {}; }
      nextTitles[currentId] = value;
      return nextTitles;
    });
  }

  const editBadges = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setBadges((curBadges) => {
      const nextBadges = {...curBadges};
      if (!nextBadges[currentId]) { nextBadges[currentId] = {}; }
      nextBadges[currentId] = value;
      return nextBadges;
    });
  }
  const editNames = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setNames((curNames) => {
      const nextNames = {...curNames};
      if (!nextNames[currentId]) { nextNames[currentId] = {}; }
      nextNames[currentId] = value;
      return nextNames;
    });
  }
  const editImages = (value) => {
    setImages(value);
  }

  const editTitles = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setTitles((curTitles) => {
      const nextTitles = {...curTitles};
      if (!nextTitles[currentId]) { nextTitles[currentId] = {}; }
      nextTitles[currentId] = value;
      return nextTitles;
    });
  }

  const loadTitles = value => {
    setTitles(value);
  }
  const loadSubtitles = value => {
    setSubtitles(value);
  }

  const loadNames = value => {
    setNames(value);
  }

  const loadBadges = value => {
    setBadges(value);
  }

  const loadPositions = value => {
    setPositions(value);
  }

  const editDates = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setDates((curDates) => {
      const nextDates = {...curDates};
      if (!nextDates[currentId]) { nextDates[currentId] = {}; }
      nextDates[currentId] = value;
      return nextDates;
    });
  }

  const editPositions = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setPositions((curPositions) => {
      const nextPositions = {...curPositions};
      if (!nextPositions[currentId]) { nextPositions[currentId] = {}; }
      nextPositions[currentId] = value;
      return nextPositions;
    });
  }

  const editProductNames = value => {
    setProductNames(value)
  }

  const editFontVariation = value => {
    setFontVariation(value);
  }

  const editText1 = value => {
    setText1(value);
  }
  const editText2 = value => {
    setText1(value);
  }

  return (
    <Edits.Provider value={{
      names,
      titles,
      positions,
      dates,
      productNames,
      fontVariation,
      images,
      text1,
      text2,
      subtitles,
      badges,
      loadTitles,
      loadSubtitles,
      loadPositions,
      loadNames,
      loadBadges,
      editProductNames,
      editNames,
      editTitles,
      editDates,
      editPositions,
      editFontVariation,
      editImages,
      reset,
      editText1,
      editText2,
      editSubtitles,
      editBadges,
    }}>
      { children }
    </Edits.Provider>
  );
}



export function useEdits() {
  const context = useContext(Edits);
  if (context === undefined) {
    throw new Error("Edits.Context must be used within the Edits.Provider");
  }
  return context;
}